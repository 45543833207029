<template>
    <Breadcrumbs main="Page" :mains="title" title="Accueil" />
    <div class="container-fluid">
        <div class="row">
            <Card3
                colClass="col-12"
                headerTitle="true"
                cardhaderClass="card-no-border pb-0"
                :title="title"
            >
                <div class="row g-xl-5 g-3">
                    <ContactEditTab :page="page" />
                    <EditContactContent
                        :page="page"
                        :availableCategories="availableCategories"
                    />
                </div>
                <div class="row">
                    <div class="col">
                        <div class="text-end">
                            <a
                                class="btn btn-success me-1"
                                target="_blank"
                                @click="save('UPDATED')"
                                >{{ $t('save') }}</a
                            >
                            <a
                                class="btn btn-primary"
                                target="_blank"
                                @click="save('PUBLISHED')"
                                >{{ $t('publish') }}</a
                            >
                        </div>
                    </div>
                </div>
            </Card3>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { useRoute } from 'vue-router';
let route = useRoute();
import { useLoaderState } from '@/store/loading';
import { ref, defineAsyncComponent, onMounted, computed } from 'vue';
import ApiServices from '@/services/apiServices';
import CategoryServices from '@/services/categoryServices';
import { error_message, success_message } from '@/components/common/toast';
import config from '@/config';
import { contact } from '@/core/data/contact';
const ContactEditTab = defineAsyncComponent(
    () => import('@/components/theme/card/CardEditTab.vue'),
);
const EditContactContent = defineAsyncComponent(
    () => import('@/components/theme/card/EditCardContent.vue'),
);

const save = async (status: string) => {
    useLoaderState().changeStateTrue();
    const lastStatus = page.value.status;
    page.value.status = status;
    if (route.query.id !== undefined) {
        ApiServices.update('pages', page.value, page.value.id)
            .then(() => {
                if (status === 'PUBLISHED') {
                    success_message('Contact publié.');
                } else {
                    success_message('Contact modifié.');
                }
            })
            .catch((ex) => {
                page.value.status = lastStatus;
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    } else {
        ApiServices.create('pages', page.value)
            .then(() => {
                if (status === 'PUBLISHED') {
                    success_message('Contact publié.');
                } else {
                    success_message('Contact créé.');
                }
            })
            .catch((ex) => {
                page.value.status = lastStatus;
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    }
};
const getDatas = async () => {
    if (route.query.id !== undefined) {
        ApiServices.getById('pages', route.query.id.toString()).then(
            (response: any) => {
                page.value = response;
            },
        );
    }
};
const availableCategories = ref([]);
const getCategories = async () => {
    CategoryServices.getCategories().then((list: []) => {
        availableCategories.value = list;
    });
};
const page = ref<contact>({
    contact: {
        contactName: '',
        profession: '',
        complement: '',
        address: undefined,
        phone: '',
        email: '',
        webSite: '',
    },
    id: '',
    title: '',
    type: config.pageType.PAGE_CARD,
    subType: 1,
    longDescription: '',
    imageUrl: '',
    homePage: false,
    drawerMenu: false,
    isLinkUrl: false,
    linkUrl: '',
    newsPage: false,
    newsPeriod: {
        startDate: undefined,
        endDate: undefined,
    },
    notify: false,
    childs: [],
    pageIcon: '',
    template: '',
    medias: [],
    params: [],
    categoryId: '',
    categories: [],
    createdBy: undefined,
    createdAt: new Date(),
    updatedBy: undefined,
    updatedAt: new Date(),
    status: 'CREATED',
});

const title = computed(() => {
    return page.value && page.value.id
        ? 'Modification du contact ' + page.value.title
        : "Création d'un contact";
});
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    getCategories();
    useLoaderState().changeStateFalse();
});
</script>