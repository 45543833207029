<template>
    <Breadcrumbs main="Categories" :mains="title" title="Accueil" />
    <div class="container-fluid">
        <div class="row">
            <Card3 colClass="col-sm-12">
                <NotificationEdit
                    :notification="notification"
                    :notificationTopics="notificationTopics"
                />
                <div class="row">
                    <div class="col">
                        <div class="text-end">
                            <a
                                class="btn btn-success me-1"
                                target="_blank"
                                @click="save()"
                                >{{ $t('save') }}</a
                            >
                        </div>
                    </div>
                </div>
            </Card3>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { useRoute } from 'vue-router';
let route = useRoute();
import { ref, defineAsyncComponent, onMounted, computed } from 'vue';
import { error_message, success_message } from '@/components/common/toast';
import ApiServices from '@/services/apiServices';
import { useLoaderState } from '@/store/loading';
import type { notification } from '@/core/data/notification';
import {
    notificationTopic,
    shortNotificationTopic,
} from '@/core/data/notificationTopic';
const NotificationEdit = defineAsyncComponent(
    () => import('@/components/theme/notification/NotificationEdit.vue'),
);
const notificationTopics = ref<shortNotificationTopic[]>([]);

let notification = ref<notification>({
    id: undefined,
    title: '',
    body: '',
    topic: undefined,
    type: 'INFORMATION',
    createdBy: undefined,
    createdAt: new Date(),
    updatedBy: undefined,
    updatedAt: new Date(),
    status: undefined,
});

const title = computed(() => {
    return notification.value && notification.value.id
        ? "Modification d'une notification"
        : "Création d'une notification";
});
const save = async () => {
    useLoaderState().changeStateTrue();
    if (route.query.email !== undefined) {
        //@ts-ignore
        ApiServices.update(
            'notificationTypes',
            notification.value,
            notification.value.id,
        )
            .then(() => {
                success_message('Notification modifiée.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    } else {
        ApiServices.create('notifications', notification.value)
            .then(() => {
                success_message('Notification créé.');
            })
            .catch((ex) => {
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    }
};
const getDatas = async () => {
    if (route.query.id !== undefined) {
        ApiServices.getById('notifications', route.query.id.toString()).then(
            (response: any) => {
                notification.value = response;
            },
        );
    }
};
const getNotificationTopics = async () => {
    ApiServices.getAll('notificationTypes', {}, ['_id', 'title', 'key'], {
        page: 1,
        rowsPerPage: 50,
        title: 'title',
        descending: true,
    }).then((response: any) => {
        if (response?.entries && response?.entries.length > 0) {
            response?.entries.forEach((topic: notificationTopic) => {
                notificationTopics.value.push({
                    id: topic.id,
                    title: topic.title,
                    key: topic.key,
                });
            });
        }
        if (
            notificationTopics.value &&
            notificationTopics.value.length > 0 &&
            notification.value.topic === undefined
        ) {
            notification.value.topic = notificationTopics.value[0];
        }
    });
};
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    getNotificationTopics();
    useLoaderState().changeStateFalse();
});
</script>