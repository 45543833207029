import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row g-xl-5 g-3" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "text-end" }

import { useRoute } from 'vue-router';
import { useLoaderState } from '@/store/loading';
import { ref, defineAsyncComponent, onMounted, computed } from 'vue';
import ApiServices from '@/services/apiServices';
import CategoryServices from '@/services/categoryServices';
import { error_message, success_message } from '@/components/common/toast';
import config from '@/config';
import { contact } from '@/core/data/contact';

export default /*@__PURE__*/_defineComponent({
  __name: 'CardEdit',
  setup(__props) {

let route = useRoute();
const ContactEditTab = defineAsyncComponent(
    () => import('@/components/theme/card/CardEditTab.vue'),
);
const EditContactContent = defineAsyncComponent(
    () => import('@/components/theme/card/EditCardContent.vue'),
);

const save = async (status: string) => {
    useLoaderState().changeStateTrue();
    const lastStatus = page.value.status;
    page.value.status = status;
    if (route.query.id !== undefined) {
        ApiServices.update('pages', page.value, page.value.id)
            .then(() => {
                if (status === 'PUBLISHED') {
                    success_message('Contact publié.');
                } else {
                    success_message('Contact modifié.');
                }
            })
            .catch((ex) => {
                page.value.status = lastStatus;
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    } else {
        ApiServices.create('pages', page.value)
            .then(() => {
                if (status === 'PUBLISHED') {
                    success_message('Contact publié.');
                } else {
                    success_message('Contact créé.');
                }
            })
            .catch((ex) => {
                page.value.status = lastStatus;
                error_message(ex);
            })
            .finally(() => {
                useLoaderState().changeStateFalse();
            });
    }
};
const getDatas = async () => {
    if (route.query.id !== undefined) {
        ApiServices.getById('pages', route.query.id.toString()).then(
            (response: any) => {
                page.value = response;
            },
        );
    }
};
const availableCategories = ref([]);
const getCategories = async () => {
    CategoryServices.getCategories().then((list: []) => {
        availableCategories.value = list;
    });
};
const page = ref<contact>({
    contact: {
        contactName: '',
        profession: '',
        complement: '',
        address: undefined,
        phone: '',
        email: '',
        webSite: '',
    },
    id: '',
    title: '',
    type: config.pageType.PAGE_CARD,
    subType: 1,
    longDescription: '',
    imageUrl: '',
    homePage: false,
    drawerMenu: false,
    isLinkUrl: false,
    linkUrl: '',
    newsPage: false,
    newsPeriod: {
        startDate: undefined,
        endDate: undefined,
    },
    notify: false,
    childs: [],
    pageIcon: '',
    template: '',
    medias: [],
    params: [],
    categoryId: '',
    categories: [],
    createdBy: undefined,
    createdAt: new Date(),
    updatedBy: undefined,
    updatedAt: new Date(),
    status: 'CREATED',
});

const title = computed(() => {
    return page.value && page.value.id
        ? 'Modification du contact ' + page.value.title
        : "Création d'un contact";
});
onMounted(() => {
    useLoaderState().changeStateTrue();
    getDatas();
    getCategories();
    useLoaderState().changeStateFalse();
});

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_Card3 = _resolveComponent("Card3")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Page",
      mains: title.value,
      title: "Accueil"
    }, null, 8, ["mains"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Card3, {
          colClass: "col-12",
          headerTitle: "true",
          cardhaderClass: "card-no-border pb-0",
          title: title.value
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(ContactEditTab), { page: page.value }, null, 8, ["page"]),
              _createVNode(_unref(EditContactContent), {
                page: page.value,
                availableCategories: availableCategories.value
              }, null, 8, ["page", "availableCategories"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("a", {
                    class: "btn btn-success me-1",
                    target: "_blank",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (save('UPDATED')))
                  }, _toDisplayString(_ctx.$t('save')), 1),
                  _createElementVNode("a", {
                    class: "btn btn-primary",
                    target: "_blank",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (save('PUBLISHED')))
                  }, _toDisplayString(_ctx.$t('publish')), 1)
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["title"])
      ])
    ])
  ], 64))
}
}

})