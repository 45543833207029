export default {
    api: {
        /*      backendUrl: 'http://localhost:9090',
        BackendApiKey: '9c09fa7d-b0c9-4f8d-8404-a3d53a98835d', */
        backendUrl:
            'https://api-mytownbackend-std-dot-mytown-257320.appspot.com',
        BackendApiKey: '9c09fa7d-b0c9-4f8d-8404-a3d53a98835d',
    },
    authentication: {
        local: true, // local or firebase
    },
    role: {
        superAdmin: 1,
        admin: 2,
        user: 10,
    },
    pageType: {
        PAGE_WEATHER: 1,
        PAGE_CARD: 2,
        PAGE_CATEGORIE: 3,
        PAGE_CONTENT: 4,
        PAGE_PAGELIST: 5,
        PAGE_EVENTS: 6,
        PAGE_CARDS: 7,
        PAGE_SOCIALNETWORKS: 8,
        PAGE_CARDLIST: 9,
        PAGE_SURVEYS: 10,
        PAGE_SURVEY: 11,
        PAGE_NEWS: 12,
        PAGE_NEWSLIST: 13,
        PAGE_REPORT: 14,
        PAGE_MAP: 15,
        PAGE_GALLERY: 16,
        PAGE_POLLUTION: 17,
    },
    pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsPerPageItems: [25, 50, 100, 300],
        totalItems: 0,
        sortBy: 'updatedAt',
        descending: false,
    },
    firebase: {
        apiKey: '',
        authDomain: '',
        projectId: '',
        storageBucket: '',
        messagingSenderId: '',
        appId: '',
        measurementId: '',
    },
};
